<template>
  <div>
    <loading v-if="pageLoading" />
    <div v-show="!pageLoading" class="container">
      <div class="page-bg">
        <img id="bgImg" src="../assets/images/invite_bg_img.jpg" />
      </div>
      <div class="content">
        <div class="logo"><img src="../assets/images/logo_white_h.png" /></div>
        <div class="words">同城交友 附近约会</div>
        <div class="qrcode-item">
          <div class="qrcode" id="qrCode" ref="qrCode"></div>
        </div>
        <div class="yqm-item">邀请码 {{ yqm }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import Loading from '../components/Loading.vue'
import QRCode from "qrcodejs2"

export default {
  name: 'Home',
  components: {
    Loading
  },
  data () {
    return {
      yqm: '',
      pageLoading: true
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.yqm = to.query.yqm
    })
  },
  mounted() {
    document.getElementById('bgImg').onload = () => {
      const {yqm} = this
      new QRCode('qrCode', {
        render: 'canvas',
        width: 300,
        height: 300,
        colorDark: "#ffffff",
        colorLight: "rgba(0,0,0,0)",
        text: yqm
      })
      this.pageLoading = false
    }
  },
  methods: {
    handleGetDtDetail () {
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    img {
      height: 100%;
      display: block;
    }
  }
  .content {
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
    }
    .logo {
      width: 334rem;
      height: 100rem;
      margin-top: 120rem;
    }
    .words {
      margin-top: 6rem;
      text-align: center;
      line-height: 38rem;
      color: #fff;
      font-size: 38rem;
    }
    .qrcode-item {
      margin-top: 258rem;
      // width: 460rem;
      // height: 460rem;
      width: 376rem;
      height: 376rem;
      padding: 36rem;
      border: 6rem solid #fff;
      border-radius: 36rem;
      position: relative;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.5);
      .qrcode {
        width: 100%;
        height: 100%;
        /deep/img {
        width: 100%;
        height: 100%;
        }
      }
    }
    .yqm-item {
      margin-top: 28rem;
      text-align: center;
      font-size: 48rem;
      color: #fff;

    }
  }
}
</style>
